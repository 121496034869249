<template>
  <div class="">
    <div class="sort">
      <select
        :value="sortOrder"
        class="w-full px-4 py-2 text-xs text-gray-700 bg-gray-300 border border-transparent rounded appearance-none cursor-pointer dark:text-gray-300 dark:border-gray-600 form-select dark:bg-transparent focus:outline-none focus:bg-white focus:border-gray-500"
        @change.prevent="$emit('sort-order-change', $event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetsExplorerToolbarSort',

  props: {
    sortOrder: {
      required: true,
      type: String
    }
  },

  data: function () {
    return {
      options: [
        { name: 'Newest', value: 'created_at:desc' },
        { name: 'Oldest', value: 'created_at:asc' },
        { name: 'Name (A-Z)', value: 'name:asc' },
        { name: 'Name (Z-A)', value: 'name:desc' },
        { name: 'Smallest', value: 'size:asc' },
        { name: 'Largest', value: 'size:desc' },
        { name: 'Status', value: 'status:asc' },
        { name: 'Type (A-Z)', value: 'type:asc' },
        { name: 'Type (Z-A)', value: 'type:desc' },
        { name: 'Uploaded by (A-Z)', value: 'uploaded_by_name:asc' },
        { name: 'Uploaded by (Z-A)', value: 'uploaded_by_name:desc' }
      ]
    }
  }
}
</script>

<style scoped>
label,
select {
  vertical-align: baseline;
}

label {
  display: none;
  margin-right: 0.5rem;
}

/* Because Semantic-UI shits all over specificity like a retarded puppy */
select.ui.dropdown {
  border: none;
  font-weight: bold;
}

@media only screen and (min-width: 760px) {
  label {
    display: inline-block;
  }
}
</style>
